/*
  Base
*/

$body-font-color: #515151;
$header-color: #333;
$hover-color: black;

body {
  font-family: "PT Serif", Georgia, "Times New Roman", serif;
  font-size: 18px;
  color: $body-font-color;
  line-height: 1.75rem;
}

@media (max-width: 30em) {
  body {
    font-size: 16px;
  }
}

h1 {
  font-size: 2rem;

  a {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quattrocento Sans", sans-serif;
  color: $header-color;
}

a {
  text-decoration: underline;
  color: $header-color;
  transition: 0.5s;

  &:hover {
    color: $hover-color;
    border-bottom: none;
  }
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

blockquote {
  border-left: 0.25rem solid #ccc;
  margin: 1.5em 10px;
  color: #555;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";

  &:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  p {
    display: inline;
  }
}


/*
  Site container
*/

.container {
  margin-left: auto;
  margin-right: auto;
  width: 615px;
}

@media (max-width: 1366px) {
  .container {
    width: 45vw;
  }
}

@media (max-width: 48em) {
  .container {
    width: 75vw;
  }
}

/*
  Masthead
*/

.masthead {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 4rem;
  font-family: "Quattrocento Sans", sans-serif;

  a:hover {
    color: $hover-color;
  }

  .masthead-title a {
    font-size: 2rem; /*25px;*/
    text-decoration: none;
    color: #505050;
  }

  .masthead-subtitle {
    color: #555;
  }
}

@media (max-width: 30rem) {
  .masthead-title small {
    display: none;
  }
}

/*
  Post
*/

.post-container {
  margin-bottom: 4rem;
}

.posts {
  margin-bottom: 5rem;
  padding: 0px;
  list-style: none;
}

.post-date {
  display: block;
  margin-bottom: 1rem;
  color: #555;
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 0.8rem;
}

.related-posts {
  padding-left: 0px;
  list-style: none;

  li {
    width: 44%;
    float: left;
    margin: 0 10px;
  }

  a {
    text-decoration: none;
  }
}

/*
  Pagination
*/

.pagination {
  overflow: hidden;
  font-family: "Quattrocento Sans", sans-serif;
  text-align: center;

  a {
    text-decoration: none;
  }

  .pagination-button {
    color: #555;
    border: 1px solid #e0e0e0;
    padding: 15px 45px;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
  }

  .pagination-active {
    color: black;

    &:hover {
      background-color: #e0e0e0;
    }
  }
}



/*
  Footer
*/

.footer {
  color: #555;
  text-align: center;
  margin: 2rem;
  clear: both;
  padding-top: 2rem;

  a {
    color: #555;
    padding: 10px;
    text-decoration: none;

    &:hover {
      color: #333;
    }
  }
}

/*
  Menu
*/

.menu {
  padding-top: 0.5rem;
  display: block;

  .menu-content {
    float: left;

    a {
      color: #555;
      font-size: 1rem;
      padding-right: 10px;
    }
  }

  .social-icons {
    float: right;

    a {
      color: #555;
      font-size: 1rem;
    }
  }
}

@media (max-width: 30em) {
  .social-icons {
    display: none;
  }
}

/*
  Code
*/

pre,
code {
  font-size: 0.8rem;
  border-radius: 3px;
  background-color: #f5f5f5;
  font-family: "Source Code Pro";
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;
}

pre > code {
  border: 0;
  padding-right: 0;
  padding-left: 0;
}

.highlight .lineno {
  color: #aaa;
  display: inline-block;
  padding: 0 5px;
  border-right: 1px solid #ccc;
}

.highlight pre code {
  display: block;
  white-space: pre;
  overflow-x: auto;
  word-wrap: normal;
}

/*
  Social media icons
*/

.sharing-icons {
  float: right;

  a {
    padding-right: 10px;
  }
}

.fa-envelope {
  padding: 5px;
  transition: 0.5s;

  &:hover {
    color: #f39c12;
  }
}

.fa-twitter {
  padding: 5px;
  transition: 0.5s;

  &:hover {
    color: #00aced;
  }
}

.fa-instagram {
  padding: 5px;
  transition: 0.5s;

  &:hover {
    color: #375989;
  }
}

.fa-github {
  padding: 5px;
  transition: 0.5s;

  &:hover {
    color: #343434;
  }
}

.fa-linkedin {
  padding: 5px;
  transition: 0.5s;

  &:hover {
    color: #0073a4;
  }
}

.fa-facebook {
  padding: 5px;
  transition: 0.5s;

  &:hover {
    color: #3d5b99;
  }
}
